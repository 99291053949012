<template>
  <div class="inner-pages hd-white">
    <div id="wrapper">
      <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
      <div id="login">
        <div class="login">
          <h2>Forgotten Your Password</h2>
          <p class="mt-3">
            Don't worry, it happens! Enter your email below and we will take
            care of it.
          </p>

          <form @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <input
                v-model="email"
                style="height: 50px"
                class="form-control"
                type="email"
              />
              <i class="ti-user"></i>
            </div>

            <div id="pass-info" class="clearfix"></div>
            <button
              @click="submit"
              style="outline: none; color: white; width: 200px; float: left"
              class="btn_1 rounded full-width add_top_30 res-button-how"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div class="login-and-register-form modal">
        <div class="main-overlay"></div>
        <div class="main-register-holder">
          <div class="main-register fl-wrap">
            <div class="close-reg"><i class="fa fa-times"></i></div>
            <h3>
              Welcome to <span>Find<strong>Houses</strong></span>
            </h3>
            <div class="soc-log fl-wrap">
              <p>Login</p>
              <a href="#" class="facebook-log"
                ><i class="fa fa-facebook-official"></i>Log in with Facebook</a
              >
              <a href="#" class="twitter-log"
                ><i class="fa fa-twitter"></i> Log in with Twitter</a
              >
            </div>
            <div class="log-separator fl-wrap"><span>Or</span></div>
            <div id="tabs-container">
              <ul class="tabs-menu">
                <li class="current"><a href="#tab-1">Login</a></li>
                <li><a href="#tab-2">Register</a></li>
              </ul>
              <div class="tab">
                <div id="tab-1" class="tab-contents">
                  <div class="custom-form">
                    <form method="post" name="registerform">
                      <label>Username or Email Address * </label>
                      <input name="email" type="text" value="" />
                      <label>Password * </label>
                      <input name="password" type="password" value="" />
                      <button type="submit" class="log-submit-btn">
                        <span>Log In</span>
                      </button>
                      <div class="clearfix"></div>
                      <div class="filter-tags">
                        <input id="check-a" type="checkbox" name="check" />
                        <label for="check-a">Remember me</label>
                      </div>
                    </form>
                    <div class="lost_password">
                      <a href="#">Lost Your Password?</a>
                    </div>
                  </div>
                </div>
                <div class="tab">
                  <div id="tab-2" class="tab-contents">
                    <div class="custom-form">
                      <form
                        @submit.prevent=""
                        name="registerform"
                        class="main-register-form"
                        id="main-register-form2"
                      >
                        <label>First Name * </label>
                        <input name="name" type="text" value="" />
                        <label>Second Name *</label>
                        <input name="name2" type="text" value="" />
                        <label>Email Address *</label>
                        <input name="email" type="text" value="" />
                        <label>Password *</label>
                        <input name="password" type="password" value="" />
                        <span class="field"></span>
                        <label>Confirm Password *</label>
                        <input name="password" type="password" value="" />
                        <button type="submit" class="log-submit-btn">
                          <span>Register</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered ref="email-modal" hide-header>
      <center>
        <p class="my-4">An email has been sent to {{ email }}</p>
      </center>
      <template #modal-footer>
        <b-button
          @click="$router.push('/login')"
          variant="primary"
          size="md"
          class="listing-button-color btn btn-primary"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    submit() {
      this.$store
        .dispatch("forgotPassword", { email: this.email })
        .then((res) => {
          if (res == true) this.$refs["email-modal"].show();
          else alert("Error! Please try again later");
        });
    },
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
#login .login,
#register .login {
  width: 500px;
  padding-top: 0px;
}
.form-control {
  border-radius: 8px;
}
h2 {
  text-transform: inherit;
  font-size: 1.3em;
}

p {
  font-weight: 500;
}
</style>
